import React from 'react';
import BlogArticle from 'components/blog/blogArticle';
import { Post } from 'components/blog/content/styled';
import { authorDaniela as author } from 'data/authors';
import { useSourceParams } from 'hooks/useSourceParams';
import img from 'img/blog/10-ui-tips-that-will-improve-your-ux-writing/cover.png';

import img1 from 'img/blog/10-ui-tips-that-will-improve-your-ux-writing/image1.jpg';
import img2 from 'img/blog/10-ui-tips-that-will-improve-your-ux-writing/image2.png';
import img3 from 'img/blog/10-ui-tips-that-will-improve-your-ux-writing/image3.png';
import img4 from 'img/blog/10-ui-tips-that-will-improve-your-ux-writing/image4.png';

const Content = () => {
  const banner = useSourceParams('banner');
  return (
    <Post>
      <h2>What is UX writing?</h2>
      <p>
        Building a website is much more complicated than thinking about the pages to add and what
        their purpose will be. It is a complex process that requires several stages of serious
        planning and strategizing. And one of the most important elements of building a website is
        building the UI. You need to think about the way you&apos;ll display the page elements and
        the way your users will find it most convenient to use them. All of this will reflect on
        your UX.
      </p>
      <p>
        So, yes, <strong>UI and UX go hand in hand</strong>, and one supports the other. Still,
        there are some UI principles and tips that could help you improve your UX writing and
        improve your website written content. We’ll break them down together in the list of 10 best
        UI tips for improving UX writing that you’ll find below.
      </p>
      <p>Let’s take a closer look.</p>
      <img src={img1} alt="A person with a laptop on the desk." title="Person with laptop" />
      <p>
        Source:{' '}
        <a
          href="https://www.pexels.com/photo/person-using-tablet-on-table-3740318/"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          Pexels
        </a>
      </p>
      <h2>What is UX Writing?</h2>
      <p>
        Before we dive deeper into the tips, let’s make it clear to everyone what exactly is UX
        writing. According to the definition, UX writing is:
      </p>
      <ul>
        <li>
          the process of creating the text that appears within the design of digital products.
        </li>
      </ul>
      <p>
        It’s like building a bridge between the product and its users. That means that UX writers
        create content that helps users understand how to use a product and supports their{' '}
        <a href="/user-experience/" target="_blank" rel="noopener noreferrer">
          user experience
        </a>
        .
      </p>
      <p>Now let’s take a look at the actual tips that will help you improve your UX writing.</p>
      <h2>1. Know Your Target Website Visitors</h2>
      <p>
        {' '}
        The first rule of building an amazing UI is to know your target audience. That means that
        you have to understand why they’re on your website and how they plan on using it.
      </p>
      <p>This principle can easily be transferred to UX writing.</p>
      <p>By knowing your target website visitors, you’ll know their:</p>
      <ul>
        <li>interests</li>
        <li>online behavior</li>
        <li>habits</li>
        <li>pain points</li>
        <li>attention triggers</li>
      </ul>
      <p>
        And just as a designer will be able to use this knowledge to create an interface that works
        best for the potential users, so can you write the content they’ll be interested in reading.
      </p>
      <a href={banner.link} className="action-banner" target="_blank" rel="noopener noreferrer">
        Get to know your target audience and make sure you’re writing for them.
      </a>
      <h2>2. Remove Redundancy</h2>
      <img
        src={img2}
        alt="Sentence before and after removing redundancy"
        title="Before and after removing redundancy"
      />
      <p>When it comes to building a highly-functional and effective website, clarity is key.</p>
      <p>
        UI designers lean on clarity more than you can imagine. This is because they know that the
        simpler the presentation, the better the response and engagement they’ll have.
      </p>
      <p>
        This is why, in your UX writing, you need to remove redundancy. Here’s what we have in mind:
      </p>
      <ul>
        <li>present information in a short, concise manner</li>
        <li>remove all the fluff words and unnecessary phrases</li>
        <li>remove anything that isn’t adding value</li>
        <li>think of the shortest and simplest way to say something</li>
      </ul>
      <p>Let’s compare:</p>
      <ul>
        <li>
          <i>Sign in to Receive our Coupons</i>
        </li>
        <li>
          <i>
            Sign in with your account to receive discount coupons you can use on any of our models
          </i>
        </li>
      </ul>
      <p>
        The second copy provides too many redundant facts that say almost the same thing as the
        first one. Keep it clear and simple to achieve the maximally positive effect.
      </p>
      <h2>3. Think of Scannability</h2>
      <img
        src={img3}
        alt="Two different expressions examples: complicated and straightforward."
        title="Think of Scannability"
      />
      <p>
        Online users don’t like to waste their time. They have so many options and websites to check
        out that they won’t stick with yours unless you provide the best possible UX.
      </p>
      <p>
        UI designers figured out a way to keep as many visitors interested in their website- they’re
        making it scannable.
      </p>
      <p>That means that the visitor only needs to take a quick look at the website and:</p>
      <ul>
        <li>find the information they’re looking for</li>
        <li>decide whether to stick around or not</li>
      </ul>
      <p>
        You need to do the same thing with your UX writing. Present the text in a way that allows
        scanning and a quick search for important information.
      </p>
      <p>Here’s what we suggest:</p>
      <ul>
        <li>break the text down using subheadings</li>
        <li>write short paragraphs</li>
        <li>create bullet points and lists</li>
      </ul>
      <p>
        Separate different information and classify them to allow the website visitors to find
        exactly what they’re looking for- quickly and without any trouble.
      </p>
      <h2>4. Open Strongly</h2>
      <p>
        When you have an audience with a limited attention span and a very narrow window to hook
        them to your webpage, you’ve got to open strongly.
      </p>
      <p>
        UI designers make sure that the most important elements are dominating the visual aspect of
        the interface.
      </p>
      <p>You need to do the same with your written copy. Make sure you open strongly:</p>
      <ul>
        <li>place the most important information at the beginning</li>
        <li>don’t waste too much time on warm-ups and introductory</li>
        <li>use the inverted pyramid principle</li>
      </ul>
      <p>
        So, make sure you hook them first and then get into greater detail about the things you are
        discussing.{' '}
      </p>
      <h2>5. Use Numbers </h2>
      <img src={img4} alt="The importance of numbers in UX writing" title="Use Numbers" />
      <p>
        The visual presentation is important. UI designers know that an image can speak a thousand
        words and that the visual aspect of a website is the primary reason why someone would decide
        to stay on it.
      </p>
      <p>
        UX writers need to use this information for their own benefit. This is why, whenever it’s
        possible, you should use numbers.
      </p>
      <p>Your users will associate numbers with:</p>
      <ul>
        <li>facts</li>
        <li>credible information</li>
        <li>statistics</li>
      </ul>
      <p>That’s why you should grab their attention using numerals, like in the example below:</p>
      <ul>
        <li>11 years of experience</li>
        <li>23 professional web designers</li>
        <li>981 websites built</li>
        <li>1000+ satisfied customers</li>
      </ul>
      <p>
        Numbers work great for both UI and UX, so make sure you incorporate them in your website
        copy.
      </p>
      <h2>6. Stay Consistent</h2>
      <p>
        Consistency is another key element in the process of building a powerful website. Every UI
        designer knows that there needs to be a consistent pattern in the way they organize each
        page of a website.
      </p>
      <p>
        The visitors need to understand how the interface is working and adopt those rules early in
        the process of searching the website.
      </p>
      <p>Well, consistency is crucial for UX writing as well.</p>
      <p>There needs to be coherence in:</p>
      <ul>
        <li>terminology</li>
        <li>writing style</li>
        <li>tone & voice</li>
        <li>vocabulary</li>
      </ul>
      <p>
        So, if you’re using the term “<strong>shopping cart</strong>” on one page and “
        <strong>shopping bag</strong>” on the other, it will confuse and even frustrate your users.
      </p>
      <p>
        Make sure you develop a writing style and stick to consistent terminology to make your users
        happy.
      </p>
      <h2>7. Provide True Value</h2>
      <p>
        Everything that you place on your website needs to provide some kind of value to the website
        visitors. If it doesn&apos;t, it&apos;s just taking up space or making them think less of
        you and your website.
      </p>
      <p>
        Just like the UI designers add or remove interface elements depending on their purpose and
        value, so should UX writers do with their written copy.
      </p>
      <p>Adding value means:</p>
      <ul>
        <li>providing useful information</li>
        <li>offering solutions and the right choices</li>
        <li>helping with the interactions on the website</li>
      </ul>
      <p>
        So, unless your written content is providing true value to the visitors, don’t make it a
        part of your UX copy.
      </p>
      <h2>8. Build a Dialogue</h2>
      <p>
        When you&apos;re building your website, you&apos;re doing it to engage the visitors and have
        them perform certain desired actions. That means that you have to provide the conditions for
        a quality dialogue with them.
      </p>
      <p>
        UI designers use different buttons, bars, icons, and message boxes to communicate with the
        users and have them actively engage in different processes on the website.
      </p>
      <p>
        UX writers need to do the same thing. And, the best way to build a dialogue as a UX writer
        is to:
      </p>
      <ul>
        <li>use everyday vocabulary</li>
        <li>use a friendly tone</li>
        <li>avoid pompous language and complex terminology</li>
      </ul>
      <p>Let’s compare:</p>
      <ul>
        <li>Logo Design: Choose a template to make your brand recognizable</li>
        <li>
          Logo Design: We offer a wide selection of distinguished logo templates that aim to achieve
          recognizability and uniqueness.
        </li>
      </ul>
      <p>They lost you at “selection” in the second example.</p>
      <p>
        Natural language means your visitors will feel more relaxed, safe, and will trust you to be
        on their side.
      </p>
      <h2>9. Make Them Active</h2>
      <p>
        The people who are on your website are there to find information, look at a product,
        consider hiring you for your services, or simply check out your brand.
      </p>
      <p>
        But, it&apos;s your job to <strong>motivate them to perform the actions</strong> you&apos;d
        like them to perform.
      </p>
      <p>This is why you need to activate them.</p>
      <p>
        Now, a UI designer will use different interface elements such as CTA buttons to get them
        going. You, on the other hand, need to use the right uplifting vocabulary. Here’s what we
        suggest:
      </p>
      <ul>
        <li>use active voice</li>
        <li>use imperative</li>
        <li>use present tense</li>
        <li>use positive, affirmative sentences</li>
      </ul>
      <p>Let’s compare:</p>
      <ul>
        <li>The link below should be clicked to avoid signing out.</li>
        <li>Click the link below to stay signed in.</li>
      </ul>
      <p>
        The second one is affirmative, clear, and inviting. It would get more people to click the
        link.
      </p>
      <h2>10. Double-Check Your Accuracy</h2>
      <p>
        A UI designer will go through several stages of pre-planning, drafting, designing, and
        editing. Before they place any element in the web design, they’ll check and reconsider their
        decision several times.
      </p>
      <p>
        You need to do the same with your written content. Make sure to double-check your accuracy
        before you publish anything.
      </p>
      <p>Here’s what that implies:</p>
      <ul>
        <li>proofread</li>
        <li>edit</li>
        <li>remove grammar mistakes, typos, and spelling mistakes</li>
        <li>consider the text flow, vocabulary, and tone</li>
      </ul>
      <h2>Final Thoughts</h2>
      <p>
        As you can see, there are so many useful UI tips and tricks that you can easily apply to UX
        writing. All you need is the right guidance and determination to make a better UX copy.{' '}
      </p>
      <p>
        Use the tips we’ve shared above to improve your UX writing and provide an upgraded
        experience for all the website visitors reading your copy.
      </p>
    </Post>
  );
};

const Wrapped = BlogArticle(Content);

export const frontmatter = {
  title: '10 UI Tips That Will Improve Your UX Writing',
  url: '/blog/10-ui-tips-that-will-improve-your-ux-writing/',
  description: `Learn about 10 best UI tips for improving UX writing to improve your website written content.`,
  author,
  img,
  imgSocial: img,
  date: '2020-10-20',
  category: '',
  group: 'Design',
  timeToRead: 7,
};

export default () => (
  <Wrapped
    title={frontmatter.title}
    category={frontmatter.category}
    description={frontmatter.description}
    date={frontmatter.date}
    author={frontmatter.author}
    url={frontmatter.url}
    img={frontmatter.img}
    imgSocial={process.env.GATSBY_HOST + frontmatter.img}
    group={frontmatter.group}
    timeToRead={frontmatter.timeToRead}
  />
);
